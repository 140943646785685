@import (reference) '../../assets/styles/variables/index.less';

.card-number {
  &_label {
    display: flex;
    align-items: center;

    &-info {
      margin-left: auto;
    }
  }

  &_value {
    text-align: right;
    font-size: 1.7rem;
    margin: 0;
  }

  &_expireDate {
    text-align: right;
    font-size: 1rem;
    margin: 0;
  }
}
